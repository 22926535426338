var render = function render(){
  var _vm$form$category, _vm$form$category2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('modal-select-group', {
    ref: "modal_select_group",
    attrs: {
      "set_category": _vm.setCategory,
      "category": _vm.form.category,
      "modal_show": _vm.modal_select_group
    }
  }), _c('b-modal', {
    attrs: {
      "id": "create-product-modal",
      "centered": "",
      "size": "xl"
    },
    on: {
      "show": _vm.setName,
      "hide": _vm.change
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function (_ref) {
        var close = _ref.close;
        return [_c('h5', [_vm._v("Новый товар")]), _c('b-button', {
          staticClass: "close",
          on: {
            "click": function ($event) {
              return close();
            }
          }
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/modal_close.svg",
            "alt": ""
          }
        })])];
      }
    }, {
      key: "modal-footer",
      fn: function (_ref2) {
        var close = _ref2.close;
        return [_c('div', {
          staticClass: "d-inline-flex w-50"
        }, [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.createProduct
          }
        }, [_vm._v(" Создать ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function ($event) {
              return close();
            }
          }
        }, [_vm._v(" Отмена ")])], 1)];
      }
    }])
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.is_loading,
      "rounded": "",
      "opacity": "0.6",
      "spinner-variant": "primary"
    }
  }, [_c('div', {
    attrs: {
      "id": "product__wrapper"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex flex-row justify-content-start"
  }, [_c('div', {
    staticClass: "product__image"
  }, [_c('div', {
    staticClass: "product__image-inner d-flex cursor"
  }, [_vm.form.photo ? [_c('img', {
    attrs: {
      "src": "/img/icons/no_photo.svg",
      "alt": ""
    }
  })] : [_c('img', {
    attrs: {
      "src": "/img/icons/no_photo.svg",
      "alt": ""
    }
  })]], 2), _c('div', {
    staticClass: "change cursor"
  }, [_vm._v("Добавить фото")])]), _c('div', {
    staticClass: "product__detail"
  }, [_c('div', {
    staticClass: "card pt-0"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "xl": "9",
      "lg": "12"
    }
  }, [_c('label', [_vm._v(" Наименование товара "), _c('span', {
    staticClass: "color-red"
  }, [_vm._v("*")])]), _c('b-form-input', {
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "xl": "3",
      "lg": "6",
      "sm": "12"
    }
  }, [_c('label', [_vm._v(" Единица измерения "), _c('span', {
    staticClass: "color-red"
  }, [_vm._v("*")])]), _c('i-select', {
    class: {
      error: _vm.measurementError
    },
    model: {
      value: _vm.form.measurement.id,
      callback: function ($$v) {
        _vm.$set(_vm.form.measurement, "id", $$v);
      },
      expression: "form.measurement.id"
    }
  }, _vm._l(_vm.measurementList, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "xl": "4",
      "lg": "6",
      "sm": "12"
    }
  }, [_c('label', [_vm._v("Тип маркированной продукции")]), _c('i-select', {
    model: {
      value: _vm.form.markingType.id,
      callback: function ($$v) {
        _vm.$set(_vm.form.markingType, "id", $$v);
      },
      expression: "form.markingType.id"
    }
  }, _vm._l(_vm.markingTypeList, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c('b-col', {
    attrs: {
      "xl": "4",
      "lg": "6",
      "sm": "12"
    }
  }, [_c('label', [_vm._v("Налоговая группа")]), _c('i-select', {
    model: {
      value: _vm.form.taxRate.id,
      callback: function ($$v) {
        _vm.$set(_vm.form.taxRate, "id", $$v);
      },
      expression: "form.taxRate.id"
    }
  }, _vm._l(_vm.taxRateList, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1)], 1)], 1)])])], 1), _c('b-row', [_c('b-col', [_c('div', {
    staticClass: "card pt-0"
  }, [_c('div', {
    staticClass: "row align-items-end"
  }, [_c('b-col', {
    attrs: {
      "col": "",
      "lg": "3",
      "md": "4"
    }
  }, [_c('label', [_vm._v("Страна")]), _c('i-select', {
    attrs: {
      "filterable": ""
    },
    model: {
      value: _vm.form.country.id,
      callback: function ($$v) {
        _vm.$set(_vm.form.country, "id", $$v);
      },
      expression: "form.country.id"
    }
  }, _vm._l(_vm.countryList, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " (" + _vm._s(item.iso) + ") ")]);
  }), 1)], 1), _c('b-col', {
    attrs: {
      "col": "",
      "lg": "3",
      "md": "4"
    }
  }, [_c('manufacturer-select', {
    attrs: {
      "manufacturer": _vm.form.manufacturer
    },
    on: {
      "setManufacturer": _vm.setManufacturer
    }
  })], 1), _c('b-col', {
    attrs: {
      "col": "",
      "lg": "3",
      "md": "4"
    }
  }, [_c('label', [_vm._v("Поставщик")]), _c('i-select', {
    attrs: {
      "filterable": "",
      "clearable": ""
    },
    model: {
      value: _vm.form.supplier.id,
      callback: function ($$v) {
        _vm.$set(_vm.form.supplier, "id", $$v);
      },
      expression: "form.supplier.id"
    }
  }, _vm._l(_vm.supplierList, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1)], 1)])])], 1), _c('b-row', [_c('b-col', [_c('div', {
    staticClass: "card pt-0 pb-0"
  }, [_c('div', {
    staticClass: "row align-items-end"
  }, [_c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('label', [_vm._v(" Расположение, группа "), _c('span', {
    staticClass: "color-red"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "b-select-group",
    class: {
      'b-select-group__error': _vm.errorCategory
    },
    on: {
      "click": _vm.openModalSelectGroup
    }
  }, [_c('div', {
    staticClass: "category-text"
  }, [_vm._v(" " + _vm._s((_vm$form$category = _vm.form.category) !== null && _vm$form$category !== void 0 && _vm$form$category.name ? (_vm$form$category2 = _vm.form.category) === null || _vm$form$category2 === void 0 ? void 0 : _vm$form$category2.name : _vm.currentCategory.name ? _vm.currentCategory.name : 'Без категории') + " ")])])]), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('label', [_vm._v("Срок годности, Дней")]), _c('b-form-input', {
    attrs: {
      "type": "number"
    },
    model: {
      value: _vm.form.expiration_date,
      callback: function ($$v) {
        _vm.$set(_vm.form, "expiration_date", $$v);
      },
      expression: "form.expiration_date"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('label', [_vm._v("Объём, Л")]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "formatter": _vm.formatter_price
    },
    model: {
      value: _vm.form.volume,
      callback: function ($$v) {
        _vm.$set(_vm.form, "volume", $$v);
      },
      expression: "form.volume"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('label', [_vm._v("Тип товарной карточки")]), _c('i-select', {
    model: {
      value: _vm.form.productType.id,
      callback: function ($$v) {
        _vm.$set(_vm.form.productType, "id", $$v);
      },
      expression: "form.productType.id"
    }
  }, _vm._l(_vm.productTypeList, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1)], 1)])])], 1), _c('b-row', [_c('b-col', [_c('div', {
    staticClass: "card pt-0 pb-0"
  }, [_c('div', {
    staticClass: "row align-items-end"
  }, [_c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "lg": "3",
      "md": "4"
    }
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.form.productType.id !== '52a4eab0-55c2-49f1-a152-f02cbc61e0b8' ? 'Закупочная цена, ₽' : 'Себестоимость, ₽') + " "), _c('img', {
    attrs: {
      "id": "price-1",
      "src": "/img/icons/icon-info.svg",
      "alt": ""
    }
  }), _c('b-tooltip', {
    attrs: {
      "target": "price-1"
    }
  }, [_vm._v(" Текст по наведению ")])], 1), _c('b-form-input', {
    attrs: {
      "type": "text",
      "disabled": _vm.form.productType.id !== '52a4eab0-55c2-49f1-a152-f02cbc61e0b8',
      "formatter": _vm.formatter_price,
      "placeholder": "0.00"
    },
    model: {
      value: _vm.form.purchasePrice,
      callback: function ($$v) {
        _vm.$set(_vm.form, "purchasePrice", $$v);
      },
      expression: "form.purchasePrice"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "lg": "3",
      "md": "4"
    }
  }, [_c('label', [_vm._v("Наценка, %")]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "0.00"
    },
    model: {
      value: _vm.form.markup,
      callback: function ($$v) {
        _vm.$set(_vm.form, "markup", $$v);
      },
      expression: "form.markup"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "lg": "3",
      "md": "4"
    }
  }, [_c('label', [_vm._v(" Розничная цена, ₽ "), _c('img', {
    attrs: {
      "id": "price-2",
      "src": "/img/icons/icon-info.svg",
      "alt": ""
    }
  }), _c('b-tooltip', {
    attrs: {
      "target": "price-2"
    }
  }, [_vm._v(" Текст по наведению ")])], 1), _c('b-form-input', {
    attrs: {
      "type": "text",
      "formatter": _vm.formatter_price,
      "placeholder": "0.00"
    },
    model: {
      value: _vm.form.retailPrice,
      callback: function ($$v) {
        _vm.$set(_vm.form, "retailPrice", $$v);
      },
      expression: "form.retailPrice"
    }
  })], 1)], 1)])])], 1)], 1), _c('div', {
    staticClass: "mt-3",
    attrs: {
      "id": "tab-barcode"
    }
  }, [_c('b-row', {
    staticClass: "b-barcode-header"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "b-barcode-scan"
  })]), _c('b-col', [_c('div', {
    staticClass: "b-barcode-btns d-flex justify-content-end"
  }, [_c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-barcode",
      modifiers: {
        "modal-barcode": true
      }
    }],
    attrs: {
      "variant": "primary"
    }
  }, [_c('img', {
    staticClass: "mr",
    attrs: {
      "src": "/img/icons/btn-plus.svg",
      "alt": ""
    }
  }), _vm._v(" Добавить штрих-код ")])], 1)])], 1), _c('modal-barcode', {
    attrs: {
      "barcodes": _vm.barcodes,
      "ean": _vm.ean
    },
    on: {
      "add-barcode": _vm.addBarcode
    }
  }), _c('b-card', {
    staticClass: "mt-3 p-0",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-table', {
    ref: "table_barcodes",
    staticClass: "table-barcodes",
    attrs: {
      "fields": _vm.fields,
      "items": _vm.barcodes.filter(function (el) {
        return el.barcode;
      }),
      "empty-filtered-text": "Нет штрих-кодов",
      "show-empty": ""
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('b-spinner', {
          attrs: {
            "variant": "primary",
            "label": "Загрузка..."
          }
        }), _c('div', [_c('strong', [_vm._v("Загрузка...")])])], 1)];
      },
      proxy: true
    }, {
      key: "cell(barcode)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.value.barcode) + " ")];
      }
    }, {
      key: "cell(value)",
      fn: function (data) {
        var _data$item$barcode;

        return [_vm.isEditPackageBarcode !== ((_data$item$barcode = data.item.barcode) === null || _data$item$barcode === void 0 ? void 0 : _data$item$barcode.id) ? [_c('div', {
          staticClass: "d-flex align-items-center cursor",
          on: {
            "click": function ($event) {
              var _data$item$barcode2;

              return _vm.toggleEditPackageBarcode(data.value, (_data$item$barcode2 = data.item.barcode) === null || _data$item$barcode2 === void 0 ? void 0 : _data$item$barcode2.id);
            }
          }
        }, [_vm._v(" " + _vm._s(data.value) + " "), _c('img', {
          staticClass: "ml-2",
          attrs: {
            "src": "/img/icons/icon_pencil_edit.svg",
            "alt": "Редактировать количество"
          }
        })])] : [_c('b-form-input', {
          staticClass: "edit-package-barcode",
          attrs: {
            "type": "number"
          },
          on: {
            "keyup": function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
              return _vm.handlerUpdatePackageBarcode(data);
            }
          },
          model: {
            value: _vm.editPackageBarcode,
            callback: function ($$v) {
              _vm.editPackageBarcode = $$v;
            },
            expression: "editPackageBarcode"
          }
        })]];
      }
    }, {
      key: "empty",
      fn: function (data) {
        return [_c('p', {
          staticClass: "center mt-3"
        }, [_vm._v(" " + _vm._s(data.emptyFilteredText) + " ")])];
      }
    }, {
      key: "cell(tools)",
      fn: function (data) {
        return [_c('button', {
          staticClass: "btn icon",
          on: {
            "click": function ($event) {
              return _vm.removeBarcode(data);
            }
          }
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/trash.svg",
            "alt": "",
            "width": "16",
            "height": "16"
          }
        })])];
      }
    }])
  })], 1)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }