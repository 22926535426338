var render = function render(){
  var _vm$found, _vm$found2, _vm$found3, _vm$notSelectedSuppli, _vm$found4;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "search_product",
    staticClass: "b-search-product"
  }, [_c('div', {
    staticClass: "b-search__wrapper"
  }, [true ? _c('create-product-modal', {
    attrs: {
      "product_name": _vm.product_name,
      "barcodes_prop": _vm.barcodes,
      "product": _vm.new_product
    },
    on: {
      "product-created": _vm.saveProduct,
      "close": _vm.closeModal
    }
  }) : _vm._e(), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "container-scanner"
  }, [_c('b-form-input', {
    ref: "searchInput",
    attrs: {
      "id": "productSearchInput",
      "type": "text",
      "placeholder": "Добавить новый товар",
      "is-keyup": true,
      "autocomplete": "off"
    },
    on: {
      "keyup": _vm.search_product
    },
    model: {
      value: _vm.searchString,
      callback: function ($$v) {
        _vm.searchString = $$v;
      },
      expression: "searchString"
    }
  }), _c('img', {
    staticClass: "scanner",
    attrs: {
      "src": "/img/icons/mini-scanner.svg",
      "alt": "scanner"
    }
  })], 1), _vm.show_add_button ? _c('e-button', {
    staticClass: "ml-2 position-relative"
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/mass-add.svg",
      "alt": "addProducts"
    },
    on: {
      "click": function ($event) {
        return _vm.$bvModal.show('mass_add_product');
      }
    }
  })]) : _vm._e()], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.search_result,
      expression: "search_result"
    }]
  }, [_c('div', [[_vm.error ? _c('div', {
    staticClass: "b-search-result error apollo"
  }, [_vm._v(" Ошибка при запросе ")]) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: (_vm$found = _vm.found) === null || _vm$found === void 0 ? void 0 : _vm$found.length,
      expression: "found?.length"
    }],
    staticClass: "b-search-result text-left search-list",
    class: {
      bottom: _vm.bottom,
      top: !_vm.bottom
    }
  }, [_vm.searchString.length ? _c('div', {
    staticClass: "create-product",
    on: {
      "click": _vm.openCreateModal
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/plus_dark.svg",
      "alt": "add"
    }
  }), _c('span', [_vm._v("Создать товар: " + _vm._s(_vm.searchString))])]) : _vm._e(), !_vm.supplier && ((_vm$found2 = _vm.found) === null || _vm$found2 === void 0 ? void 0 : _vm$found2.length) > 0 ? [_c('p', {
    staticClass: "supplier_title"
  }, [_vm._v("Все поставщики")]), _vm._l(_vm.found, function (item, index) {
    var _item$supplier, _item$supplier2;

    return _c('div', {
      directives: [{
        name: "click-outside",
        rawName: "v-click-outside",
        value: _vm.clickOutside,
        expression: "clickOutside"
      }],
      key: index,
      staticClass: "no-control item cursor d-flex",
      on: {
        "click": function ($event) {
          return _vm.select_product(item);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex flex-column flex-fill"
    }, [_c('div', {
      staticClass: "d-flex flex-fill"
    }, [_c('div', {
      staticClass: "no-control item-name"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('div', {
      staticClass: "ml-auto remainder"
    }, [_vm._v(" " + _vm._s(_vm.getRemainders(item)) + " ")])]), _c('div', {
      staticClass: "no-control item-info d-flex flex-fill"
    }, [_c('div', {
      staticClass: "d-flex flex-fill"
    }, [_c('div', {
      staticClass: "mr-2 no-control"
    }, [_vm._v(" " + _vm._s(item.articul ? item.articul : '-----') + " ")]), _c('div', {
      staticClass: "no-control mr-2"
    }, [_vm._v(" " + _vm._s((_item$supplier = item.supplier) !== null && _item$supplier !== void 0 && _item$supplier.name ? (_item$supplier2 = item.supplier) === null || _item$supplier2 === void 0 ? void 0 : _item$supplier2.name : 'Нет данных') + " ")]), _c('div', {
      staticClass: "no-control ml-auto",
      staticStyle: {
        "float": "right",
        "margin-right": "0"
      }
    }, [_vm._v(" " + _vm._s(item.retailPrice ? item.retailPrice : `0.00`) + " ₽ ")])])])])]);
  })] : _vm._e(), (_vm$found3 = _vm.found) !== null && _vm$found3 !== void 0 && _vm$found3.length && _vm.supplier ? _c('div', [_vm.selectedSupplier.length ? _c('div', [_c('p', {
    staticClass: "supplier_title"
  }, [_vm._v("Выбранный поставщик")]), _vm._l(_vm.selectedSupplier, function (item, index) {
    var _item$supplier3, _item$supplier4;

    return _c('div', {
      directives: [{
        name: "click-outside",
        rawName: "v-click-outside",
        value: _vm.clickOutside,
        expression: "clickOutside"
      }],
      key: index,
      staticClass: "no-control item cursor d-flex",
      on: {
        "click": function ($event) {
          return _vm.select_product(item);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex flex-column flex-fill"
    }, [_c('div', {
      staticClass: "d-flex flex-fill"
    }, [_c('div', {
      staticClass: "no-control item-name"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('div', {
      staticClass: "ml-auto remainder"
    }, [_vm._v(" " + _vm._s(_vm.getRemainders(item)) + " ")])]), _c('div', {
      staticClass: "no-control item-info d-flex flex-fill"
    }, [_c('div', {
      staticClass: "d-flex flex-fill"
    }, [_c('div', {
      staticClass: "mr-2 no-control"
    }, [_vm._v(" " + _vm._s(item.articul ? item.articul : '-----') + " ")]), _c('div', {
      staticClass: "no-control mr-2"
    }, [_vm._v(" " + _vm._s((_item$supplier3 = item.supplier) !== null && _item$supplier3 !== void 0 && _item$supplier3.name ? (_item$supplier4 = item.supplier) === null || _item$supplier4 === void 0 ? void 0 : _item$supplier4.name : 'Нет данных') + " ")]), _c('div', {
      staticClass: "no-control ml-auto",
      staticStyle: {
        "float": "right",
        "margin-right": "0"
      }
    }, [_vm._v(" " + _vm._s(item.retailPrice ? item.retailPrice : `0.00`) + " ₽ ")])])])])]);
  })], 2) : _vm._e(), (_vm$notSelectedSuppli = _vm.notSelectedSupplier) !== null && _vm$notSelectedSuppli !== void 0 && _vm$notSelectedSuppli.length ? _c('div', [_c('p', {
    staticClass: "supplier_title border-b"
  }, [_vm._v(" " + _vm._s(_vm.selectedSupplier.length ? 'Остальные поставщики' : 'Все поставщики') + " ")]), _vm._l(_vm.notSelectedSupplier, function (item, index) {
    var _item$supplier5, _item$supplier6;

    return _c('div', {
      directives: [{
        name: "click-outside",
        rawName: "v-click-outside",
        value: _vm.clickOutside,
        expression: "clickOutside"
      }],
      key: index,
      staticClass: "no-control item cursor d-flex",
      on: {
        "click": function ($event) {
          return _vm.select_product(item);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex flex-column flex-fill"
    }, [_c('div', {
      staticClass: "d-flex flex-fill"
    }, [_c('div', {
      staticClass: "no-control item-name"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('div', {
      staticClass: "ml-auto remainder"
    }, [_vm._v(" " + _vm._s(_vm.getRemainders(item)) + " ")])]), _c('div', {
      staticClass: "no-control item-info d-flex flex-fill"
    }, [_c('div', {
      staticClass: "d-flex flex-fill"
    }, [_c('div', {
      staticClass: "mr-2 no-control"
    }, [_vm._v(" " + _vm._s(item.articul ? item.articul : '-----') + " ")]), _c('div', {
      staticClass: "no-control mr-2"
    }, [_vm._v(" " + _vm._s((_item$supplier5 = item.supplier) !== null && _item$supplier5 !== void 0 && _item$supplier5.name ? (_item$supplier6 = item.supplier) === null || _item$supplier6 === void 0 ? void 0 : _item$supplier6.name : 'Нет данных') + " ")]), _c('div', {
      staticClass: "no-control ml-auto",
      staticStyle: {
        "float": "right",
        "margin-right": "0"
      }
    }, [_vm._v(" " + _vm._s(item.retailPrice ? item.retailPrice : `0.00`) + " ₽ ")])])])])]);
  })], 2) : _vm._e()]) : _vm._e(), !_vm.loading && !((_vm$found4 = _vm.found) !== null && _vm$found4 !== void 0 && _vm$found4.length) ? [_c('div', {
    staticClass: "center m-2 mt-3"
  }, [_vm._v("Не найдено")])] : _vm._e(), _vm.loading ? [_c('div', {
    staticClass: "mt-3 mb-1 w-100 d-flex justify-content-center"
  }, [_c('e-spinner', {
    attrs: {
      "width": 22,
      "height": 22
    }
  })], 1)] : _vm._e()], 2)]], 2)])], 1), _c('global-product-modal', {
    ref: "modal_global",
    attrs: {
      "type_add": 'modal',
      "product": _vm.new_product,
      "barcode": _vm.searchString
    },
    on: {
      "clearInput": _vm.clearInput,
      "openCreateModal": _vm.openCreateModal
    }
  }), _c('modal-scan', {
    ref: "modal_scan",
    attrs: {
      "type_add": 'modal',
      "barcode": _vm.searchString
    },
    on: {
      "clearInput": _vm.clearInput,
      "openCreateModal": _vm.openCreateModal
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }