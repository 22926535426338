<template>
  <section>
    <modal-select-group
      ref="modal_select_group"
      :set_category="setCategory"
      :category="form.category"
      :modal_show="modal_select_group"
    />
    <b-modal
      id="create-product-modal"
      centered
      size="xl"
      @show="setName"
      @hide="change"
    >
      <template #modal-header="{ close }">
        <h5>Новый товар</h5>
        <b-button
          class="close"
          @click="close()"
        >
          <img
            src="/img/icons/modal_close.svg"
            alt=""
          />
        </b-button>
      </template>
      <b-overlay
        :show="is_loading"
        rounded
        opacity="0.6"
        spinner-variant="primary"
      >
        <div id="product__wrapper">
          <b-row>
            <b-col class="d-flex flex-row justify-content-start">
              <div class="product__image">
                <div class="product__image-inner d-flex cursor">
                  <template v-if="form.photo">
                    <img
                      src="/img/icons/no_photo.svg"
                      alt=""
                    />
                  </template>
                  <template v-else>
                    <img
                      src="/img/icons/no_photo.svg"
                      alt=""
                    />
                  </template>
                </div>
                <div class="change cursor">Добавить фото</div>
              </div>
              <div class="product__detail">
                <div class="card pt-0">
                  <b-row>
                    <b-col
                      xl="9"
                      lg="12"
                      class="mb-3"
                    >
                      <label>
                        Наименование товара
                        <span class="color-red">*</span>
                      </label>
                      <b-form-input
                        v-model="form.name"
                        type="text"
                      />
                    </b-col>
                    <b-col
                      xl="3"
                      lg="6"
                      sm="12"
                      class="mb-3"
                    >
                      <label>
                        Единица измерения
                        <span class="color-red">*</span>
                      </label>
                      <i-select
                        v-model="form.measurement.id"
                        :class="{ error: measurementError }"
                      >
                        <i-option
                          v-for="item in measurementList"
                          :key="item.id"
                          :value="item.id"
                        >
                          {{ item.name }}
                        </i-option>
                      </i-select>
                    </b-col>
                    <b-col
                      xl="4"
                      lg="6"
                      sm="12"
                      class="mb-3"
                    >
                      <label>Тип маркированной продукции</label>
                      <i-select v-model="form.markingType.id">
                        <i-option
                          v-for="item in markingTypeList"
                          :key="item.id"
                          :value="item.id"
                        >
                          {{ item.name }}
                        </i-option>
                      </i-select>
                    </b-col>
                    <b-col
                      xl="4"
                      lg="6"
                      sm="12"
                    >
                      <label>Налоговая группа</label>
                      <i-select v-model="form.taxRate.id">
                        <i-option
                          v-for="item in taxRateList"
                          :key="item.id"
                          :value="item.id"
                        >
                          {{ item.name }}
                        </i-option>
                      </i-select>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="card pt-0">
                <div class="row align-items-end">
                  <b-col
                    col
                    lg="3"
                    md="4"
                  >
                    <label>Страна</label>
                    <i-select
                      v-model="form.country.id"
                      filterable
                    >
                      <i-option
                        v-for="item in countryList"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.name }} ({{ item.iso }})
                      </i-option>
                    </i-select>
                  </b-col>
                  <b-col
                    col
                    lg="3"
                    md="4"
                  >
                    <manufacturer-select
                      :manufacturer="form.manufacturer"
                      @setManufacturer="setManufacturer"
                    />
                  </b-col>
                  <b-col
                    col
                    lg="3"
                    md="4"
                  >
                    <label>Поставщик</label>
                    <i-select
                      v-model="form.supplier.id"
                      filterable
                      clearable
                    >
                      <i-option
                        v-for="item in supplierList"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </i-option>
                    </i-select>
                  </b-col>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="card pt-0 pb-0">
                <div class="row align-items-end">
                  <b-col
                    lg="3"
                    md="6"
                    sm="6"
                    class="mb-3"
                  >
                    <label>
                      Расположение, группа
                      <span class="color-red">*</span>
                    </label>
                    <div
                      class="b-select-group"
                      :class="{ 'b-select-group__error': errorCategory }"
                      @click="openModalSelectGroup"
                    >
                      <div class="category-text">
                        {{
                          form.category?.name
                            ? form.category?.name
                            : currentCategory.name
                            ? currentCategory.name
                            : 'Без категории'
                        }}
                      </div>
                    </div>
                  </b-col>
                  <b-col
                    lg="3"
                    md="6"
                    sm="6"
                    class="mb-3"
                  >
                    <label>Срок годности, Дней</label>
                    <b-form-input
                      v-model="form.expiration_date"
                      type="number"
                    />
                  </b-col>
                  <b-col
                    lg="3"
                    md="6"
                    sm="6"
                    class="mb-3"
                  >
                    <label>Объём, Л</label>
                    <b-form-input
                      v-model="form.volume"
                      type="text"
                      :formatter="formatter_price"
                    />
                  </b-col>
                  <b-col
                    lg="3"
                    md="6"
                    sm="6"
                    class="mb-3"
                  >
                    <label>Тип товарной карточки</label>
                    <i-select v-model="form.productType.id">
                      <i-option
                        v-for="item in productTypeList"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </i-option>
                    </i-select>
                  </b-col>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="card pt-0 pb-0">
                <div class="row align-items-end">
                  <b-col
                    lg="3"
                    md="4"
                    class="mb-3"
                  >
                    <label>
                      {{
                        form.productType.id !== '52a4eab0-55c2-49f1-a152-f02cbc61e0b8'
                          ? 'Закупочная цена, ₽'
                          : 'Себестоимость, ₽'
                      }}
                      <img
                        id="price-1"
                        src="/img/icons/icon-info.svg"
                        alt=""
                      />
                      <b-tooltip target="price-1"> Текст по наведению </b-tooltip>
                    </label>
                    <b-form-input
                      v-model="form.purchasePrice"
                      type="text"
                      :disabled="form.productType.id !== '52a4eab0-55c2-49f1-a152-f02cbc61e0b8'"
                      :formatter="formatter_price"
                      placeholder="0.00"
                    />
                  </b-col>
                  <b-col
                    lg="3"
                    md="4"
                    class="mb-3"
                  >
                    <label>Наценка, %</label>
                    <b-form-input
                      v-model="form.markup"
                      type="text"
                      placeholder="0.00"
                    />
                  </b-col>
                  <b-col
                    lg="3"
                    md="4"
                    class="mb-3"
                  >
                    <label>
                      Розничная цена, ₽
                      <img
                        id="price-2"
                        src="/img/icons/icon-info.svg"
                        alt=""
                      />
                      <b-tooltip target="price-2"> Текст по наведению </b-tooltip>
                    </label>
                    <b-form-input
                      v-model="form.retailPrice"
                      type="text"
                      :formatter="formatter_price"
                      placeholder="0.00"
                    />
                  </b-col>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <div
          id="tab-barcode"
          class="mt-3"
        >
          <b-row class="b-barcode-header">
            <b-col md="6">
              <div class="b-barcode-scan"></div>
            </b-col>
            <b-col>
              <div class="b-barcode-btns d-flex justify-content-end">
                <b-button
                  v-b-modal.modal-barcode
                  variant="primary"
                >
                  <img
                    src="/img/icons/btn-plus.svg"
                    class="mr"
                    alt=""
                  />
                  Добавить штрих-код
                </b-button>
              </div>
            </b-col>
          </b-row>
          <modal-barcode
            :barcodes="barcodes"
            :ean="ean"
            @add-barcode="addBarcode"
          />
          <b-card
            no-body
            class="mt-3 p-0"
          >
            <b-table
              ref="table_barcodes"
              :fields="fields"
              :items="barcodes.filter((el) => el.barcode)"
              empty-filtered-text="Нет штрих-кодов"
              show-empty
              class="table-barcodes"
            >
              <template #table-busy>
                <div class="text-center">
                  <b-spinner
                    variant="primary"
                    label="Загрузка..."
                  />
                  <div><strong>Загрузка...</strong></div>
                </div>
              </template>
              <template #cell(barcode)="data">
                {{ data.value.barcode }}
              </template>

              <template #cell(value)="data">
                <template v-if="isEditPackageBarcode !== data.item.barcode?.id">
                  <div
                    class="d-flex align-items-center cursor"
                    @click="toggleEditPackageBarcode(data.value, data.item.barcode?.id)"
                  >
                    {{ data.value }}
                    <img
                      src="/img/icons/icon_pencil_edit.svg"
                      class="ml-2"
                      alt="Редактировать количество"
                    />
                  </div>
                </template>
                <template v-else>
                  <b-form-input
                    v-model="editPackageBarcode"
                    type="number"
                    class="edit-package-barcode"
                    @keyup.enter="handlerUpdatePackageBarcode(data)"
                  />
                </template>
              </template>

              <template #empty="data">
                <p class="center mt-3">
                  {{ data.emptyFilteredText }}
                </p>
              </template>
              <template #cell(tools)="data">
                <button
                  class="btn icon"
                  @click="removeBarcode(data)"
                >
                  <img
                    src="/img/icons/trash.svg"
                    alt=""
                    width="16"
                    height="16"
                  />
                </button>
              </template>
            </b-table>
          </b-card>
        </div>
      </b-overlay>

      <template #modal-footer="{ close }">
        <div class="d-inline-flex w-50">
          <b-button
            variant="primary"
            @click="createProduct"
          >
            Создать
          </b-button>
          <b-button
            variant="outline-primary"
            @click="close()"
          >
            Отмена
          </b-button>
        </div>
      </template>
    </b-modal>
  </section>
</template>

<script>
  import { ProductModel } from '@/models/product.model'
  import { mapGetters } from 'vuex'
  import ManufacturerSelect from '@/views/products/components/ManufacturerSelect.vue'
  import { CountryModel } from '@/models/country.model'
  import { MarkingTypeModel } from '@/models/marking-type.model'
  import { MeasurementModel } from '@/models/measurement.model'
  import { ProductTypeModel } from '@/models/product-type.model'
  import { TaxRateModel } from '@/models/tax-rate.model'
  import ModalSelectGroup from '@/views/products/modal/ModalSelectGroup.vue'
  import ModalBarcode from '@/views/products/modal/ModalBarcode.vue'

  export default {
    name: 'CreateProductModal',
    components: { ModalBarcode, ModalSelectGroup, ManufacturerSelect },
    props: {
      product_name: {
        type: String,
        default: ''
      },
      barcodes_prop: {
        type: Array,
        default: () => []
      },
      product: {
        type: Object,
        default: () => {}
      }
    },
    watch: {
      'form.measurement.id'(newVal) {
        this.measurementError = false
        this.measurementList.forEach(({ id, name }) => {
          if (newVal !== id) return
          if (name === 'КГ.') return (this.ean = 'weight')
          return (this.ean = 'piece')
        })
      },
      barcodes_prop() {
        if (Array.isArray(this.barcodes_prop)) {
          this.barcodes = this.barcodes_prop
        }
      },
      async product() {
        if (this.product?.name && this.product?.name !== this.form.name) {
          this.form = new ProductModel({ ...this.product, category: this.category })
        }
      }
    },
    async mounted() {
      if (Array.isArray(this.barcodes_prop)) {
        this.barcodes = this.barcodes_prop
      }
      if (this.product?.name) {
        const { data } = await this.$apollo.query({
          query: require('../../../products/gql/getCategory.gql'),
          variables: {
            id: this?.product?.category,
            branch: this.currentBranch.id
          }
        })
        this.category = { id: data.Category.id, name: data.Category.name }
        this.form = new ProductModel({ ...this.product, category: this.category })
      }
    },
    apollo: {
      ProductDefault: {
        query: require('../../../products/gql/getProductDefault.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.setDefaultSettings(data.ProductDefault)
          this.defaults = data.ProductDefault
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },
      Measurements: {
        query: require('../../../products/gql/getMeasurements.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.measurementList = data.Measurements
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },
      MarkingTypes: {
        query: require('../../../products/gql/getMarkingTypes.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.markingTypeList = data.MarkingTypes
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },
      TaxRates: {
        query: require('../../../products/gql/getTaxRates.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.taxRateList = data.TaxRates
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },
      Countries: {
        query: require('../../../products/gql/getCountries.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.countryList = data.Countries
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },
      Suppliers: {
        query: require('../../../products/gql/getSuppliers.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.supplierList = [
            // { id: null, name: 'Все' },
            ...(data.Suppliers ?? [])
          ]
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },
      ProductTypes: {
        query: require('../../../products/gql/getProductTypes.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.productTypeList = data.ProductTypes?.filter((el) => el.name !== 'Оборудование') ?? []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },
    data() {
      return {
        measurementError: false,
        errorCategory: false,
        category: {},
        form: new ProductModel(),
        measurementList: [],
        markingTypeList: [],
        taxRateList: [],
        countryList: [],
        supplierList: [],
        productTypeList: [],
        modal_select_group: false,
        defaults: null,
        is_loading: false,
        barcodes: [],
        ean: 'piece',
        isEditPackageBarcode: false,
        editPackageBarcode: null,
        fields: [
          {
            key: 'barcode',
            label: 'Штрихкод',
            class: 'w-40'
          },
          {
            key: 'value',
            label: 'Количество в упаковке, шт'
          },
          {
            key: 'tools',
            label: 'Действия',
            class: 'w-10'
          }
        ]
      }
    },
    methods: {
      change() {
        this.$emit('close')
      },
      setName() {
        if (!this.product?.name) {
          this.form.name = this.product_name
        }
      },
      formatter_price(value) {
        return value.replace(/^\.|[^\d.]|\.(?=.*\.)|^0+(?=\d)/g, '')
      },
      setManufacturer(manufacturer) {
        this.form.manufacturer = manufacturer
        this.$bvModal.hide('manufacture-modal')
      },
      openModalSelectGroup() {
        this.$refs.modal_select_group.showModal()
      },
      setCategory(category) {
        if (category) {
          this.errorCategory = false
          this.form.category = category
        }
      },
      async createProduct() {
        if (!(this.form.category?.name ? this.form.category?.name : this.currentCategory.name)) {
          this.errorCategory = true
          return this.$noty.error('Не выбрана группа товара')
        }
        const prod = await this.handlerSaveProduct()
        this.$emit('product-created', prod)
        this.$bvModal.hide('create-product-modal')
        this.$emit('close')
        this.barcodes = []
      },
      async handlerSaveProduct() {
        const f = this.form
        if (!f.measurement.id) {
          this.measurementError = true
          return this.$noty.error('Необходимо выбрать еденицу измерения')
        }
        this.is_loading = true
        this.barcodes =
          this.barcodes.map((obj) => {
            if (obj?.product) delete obj.product
            return obj
          }) ?? []
        const { data } = await this.$apollo.mutate({
          mutation: require(`../../../products/gql/createProduct.gql`),
          variables: {
            input: {
              id: '',
              name: f.name,
              articul: f.articul,
              print_name: f.print_name,
              branch: this.currentBranch.id,
              category: f.category?.id || this.currentCategory.id,
              measurement: f.measurement.id,
              supplier: f?.supplier?.id,
              expiration_date: Number(f.expiration_date),
              volume: f.volume ? Number(f.volume) : 0,
              purchasePrice: f.purchasePrice ? Number(f.purchasePrice) : 0,
              retailPrice: f.retailPrice ? Number(f.retailPrice) : 0,
              markup: f.markup ? Number(f.markup) : 0,
              productType: f.productType.id ?? this.defaults?.productType?.id,
              markingType: f.markingType.id ?? this.defaults?.markingType?.id,
              taxRate: f.taxRate.id ?? this.defaults?.taxRate?.id,
              country: f.country.id,
              package: this.barcodes,
              manufacturer: f.manufacturer?.id,
              external_id: f?.ext_system?.other ?? null
            }
          }
        })

        this.is_loading = false
        return data.CreateProduct
      },
      setDefaultSettings(product) {
        this.form.country = new CountryModel(product.country)
        this.form.markingType = new MarkingTypeModel(product.markingType)
        this.form.measurement = new MeasurementModel(product.measurement)
        this.form.productType = new ProductTypeModel(product.productType)
        this.form.taxRate = new TaxRateModel(product.taxRate)
        // this.form = R.mergeRight(this.form, product)
      },
      addBarcode(barcode_data) {
        const barcode = barcode_data?.barcode_item?.barcode?.barcode || barcode_data
        const value = Number(barcode_data.barcode_item?.value || 1)
        const item = {
          barcode: {
            barcode: barcode
          },
          value: Number(value)
        }

        return this.barcodes.push({ ...item })
      },
      toggleEditPackageBarcode(val, id) {
        this.editPackageBarcode = val
        this.isEditPackageBarcode = this.isEditPackageBarcode === id ? false : id
      },
      handlerUpdatePackageBarcode({ item }) {
        this.barcodes = this.barcodes.map((obj) => {
          if (obj.id === item.id) {
            obj.value = this.editPackageBarcode
            return obj
          }
          return obj
        })
        // this.$apollo
        //   .mutate({
        //     mutation: require('../../../products/gql/UpdatePackage.gql'),
        //     variables: {
        //       id: item.id,
        //       value: Number(this.editPackageBarcode)
        //     }
        //   })
        //   .then(({ data }) => {
        //     this.barcodes[index].value = data.UpdatePackage.value
        //     this.editPackageBarcode = null
        //     this.isEditPackageBarcode = false
        //     // this.barcodes.push({ ...item })
        //   })
        //   .catch(() =>
        //     this.$noty.show(
        //       `При изменении штрихкода что-то пошло не так. Попробуйте еще раз`
        //     )
        //   )
      },
      removeBarcode({ index }) {
        this.barcodes.splice(index, 1)
      }
    },
    computed: {
      ...mapGetters({
        currentCategory: 'products/getCurrentCategory',
        currentBranch: 'settings/getCurrentBranch'
      })
    }
  }
</script>

<style scoped lang="scss">
  #product__wrapper {
    .row {
      margin-top: 16px;
    }

    .product {
      &__image {
        width: 168px;
        margin-right: 20px;
        text-align: center;

        &-inner {
          width: 100%;
          height: 168px;
          background: #f3f3f3;
          border-radius: 6px;
          overflow: hidden;
          justify-content: center;
          align-items: center;
        }

        .change {
          font-size: 16px;
          line-height: 24px;
          color: #888888;
          text-decoration: underline;
          margin-top: 10px;
        }

        .ivu-upload {
          &-select {
            width: 100%;
          }

          &-drag {
            border: none;
            background: none;
          }
        }
      }

      &__detail {
        flex: 1;
      }
    }

    .card {
      background: #ffffff;
      border: 0.5px solid #e1e1e1;
      border-radius: 3px;
      padding: 22px;
      margin-bottom: 12px;

      label {
        display: flex;
        align-items: center;

        img {
          margin-left: 5px;
        }
      }

      .row.mb-4 {
        margin-bottom: 20px !important;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .tabs-bottom {
      margin-top: 28px;

      .tab-content {
        padding-top: 22px;
      }
    }

    .small-width {
      .ivu-select {
        width: 130px;
      }
    }
  }

  .b-select-group {
    border: 1px solid #bcbcbc;
    border-radius: 2px;
    height: 32px;
    font-size: 14px;
    padding: 5px 10px;
    font-weight: 400;
    line-height: 1.5;
    cursor: pointer;
    background: url('/img/icons/products/pencil-edit.svg') 96% 50% no-repeat;
    background-size: 14px;
    &__error {
      border-color: #e53835;
    }
  }
  .category-text {
    padding-right: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
