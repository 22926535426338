<template>
  <div
    ref="search_product"
    class="b-search-product"
  >
    <div class="b-search__wrapper">
      <create-product-modal
        v-if="true"
        :product_name="product_name"
        :barcodes_prop="barcodes"
        :product="new_product"
        @product-created="saveProduct"
        @close="closeModal"
      />
      <div class="d-flex">
        <div class="container-scanner">
          <b-form-input
            id="productSearchInput"
            ref="searchInput"
            v-model="searchString"
            type="text"
            placeholder="Добавить новый товар"
            :is-keyup="true"
            autocomplete="off"
            @keyup="search_product"
          />
          <img
            class="scanner"
            src="/img/icons/mini-scanner.svg"
            alt="scanner"
          />
        </div>
        <e-button
          v-if="show_add_button"
          class="ml-2 position-relative"
        >
          <img
            src="/img/icons/mass-add.svg"
            alt="addProducts"
            @click="$bvModal.show('mass_add_product')"
          />
        </e-button>
      </div>

      <div v-show="search_result">
        <div>
          <template v-click-outside="clickOutside">
            <div
              v-if="error"
              class="b-search-result error apollo"
            >
              Ошибка при запросе
            </div>
            <div
              v-show="found?.length"
              :class="{ bottom: bottom, top: !bottom }"
              class="b-search-result text-left search-list"
            >
              <div
                v-if="searchString.length"
                class="create-product"
                @click="openCreateModal"
              >
                <img
                  src="/img/icons/plus_dark.svg"
                  alt="add"
                />
                <span>Создать товар: {{ searchString }}</span>
              </div>
              <template v-if="!supplier && found?.length > 0">
                <p class="supplier_title">Все поставщики</p>
                <template>
                  <div
                    v-for="(item, index) of found"
                    :key="index"
                    v-click-outside="clickOutside"
                    class="no-control item cursor d-flex"
                    @click="select_product(item)"
                  >
                    <div class="d-flex flex-column flex-fill">
                      <div class="d-flex flex-fill">
                        <div class="no-control item-name">
                          {{ item.name }}
                        </div>
                        <div class="ml-auto remainder">
                          {{ getRemainders(item) }}
                        </div>
                      </div>

                      <div class="no-control item-info d-flex flex-fill">
                        <div class="d-flex flex-fill">
                          <div class="mr-2 no-control">
                            {{ item.articul ? item.articul : '-----' }}
                          </div>
                          <div class="no-control mr-2">
                            {{ item.supplier?.name ? item.supplier?.name : 'Нет данных' }}
                          </div>
                          <div
                            class="no-control ml-auto"
                            style="float: right; margin-right: 0"
                          >
                            {{ item.retailPrice ? item.retailPrice : `0.00` }}
                            ₽
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
              <div v-if="found?.length && supplier">
                <div v-if="selectedSupplier.length">
                  <p class="supplier_title">Выбранный поставщик</p>
                  <template>
                    <div
                      v-for="(item, index) of selectedSupplier"
                      :key="index"
                      v-click-outside="clickOutside"
                      class="no-control item cursor d-flex"
                      @click="select_product(item)"
                    >
                      <div class="d-flex flex-column flex-fill">
                        <div class="d-flex flex-fill">
                          <div class="no-control item-name">
                            {{ item.name }}
                          </div>
                          <div class="ml-auto remainder">
                            {{ getRemainders(item) }}
                          </div>
                        </div>

                        <div class="no-control item-info d-flex flex-fill">
                          <div class="d-flex flex-fill">
                            <div class="mr-2 no-control">
                              {{ item.articul ? item.articul : '-----' }}
                            </div>
                            <div class="no-control mr-2">
                              {{ item.supplier?.name ? item.supplier?.name : 'Нет данных' }}
                            </div>
                            <div
                              class="no-control ml-auto"
                              style="float: right; margin-right: 0"
                            >
                              {{ item.retailPrice ? item.retailPrice : `0.00` }}
                              ₽
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
                <div v-if="notSelectedSupplier?.length">
                  <p class="supplier_title border-b">
                    {{ selectedSupplier.length ? 'Остальные поставщики' : 'Все поставщики' }}
                  </p>
                  <template>
                    <div
                      v-for="(item, index) of notSelectedSupplier"
                      :key="index"
                      v-click-outside="clickOutside"
                      class="no-control item cursor d-flex"
                      @click="select_product(item)"
                    >
                      <div class="d-flex flex-column flex-fill">
                        <div class="d-flex flex-fill">
                          <div class="no-control item-name">
                            {{ item.name }}
                          </div>
                          <div class="ml-auto remainder">
                            {{ getRemainders(item) }}
                          </div>
                        </div>

                        <div class="no-control item-info d-flex flex-fill">
                          <div class="d-flex flex-fill">
                            <div class="mr-2 no-control">
                              {{ item.articul ? item.articul : '-----' }}
                            </div>
                            <div class="no-control mr-2">
                              {{ item.supplier?.name ? item.supplier?.name : 'Нет данных' }}
                            </div>
                            <div
                              class="no-control ml-auto"
                              style="float: right; margin-right: 0"
                            >
                              {{ item.retailPrice ? item.retailPrice : `0.00` }}
                              ₽
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>

              <template v-if="!loading && !found?.length">
                <div class="center m-2 mt-3">Не найдено</div>
              </template>
              <template v-if="loading">
                <div class="mt-3 mb-1 w-100 d-flex justify-content-center">
                  <e-spinner
                    :width="22"
                    :height="22"
                  />
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
    <global-product-modal
      ref="modal_global"
      :type_add="'modal'"
      :product="new_product"
      :barcode="searchString"
      @clearInput="clearInput"
      @openCreateModal="openCreateModal"
    />
    <modal-scan
      ref="modal_scan"
      :type_add="'modal'"
      :barcode="searchString"
      @clearInput="clearInput"
      @openCreateModal="openCreateModal"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import CreateProductModal from '@/views/operational-processes/components/incoming/CreateProductModal.vue'
  import ModalScan from '@/views/products/modal/ModalScan.vue'
  import GlobalProductModal from '@/views/products/modal/GlobalProductModal'
  import { EventBus } from '@/main'

  export default {
    name: 'ProductSearch',
    components: { GlobalProductModal, CreateProductModal, ModalScan },

    apollo: {
      ProductSearchAndSpec: {
        query: require('../gql/searchProductAndSpec.gql'),
        fetchPolicy: 'no-cache',
        debounce: 300,
        notifyOnNetworkStatusChange: true,
        variables() {
          return {
            supplier: this.supplier,
            name: this.searchString,
            branch: this.currentBranch.id,
            document_head: this.document_head,
            document_type: this.document_type,
            pagination: this.pagination
          }
        },
        skip() {
          return this.searchString.length < 1
        },
        async result({ data }) {
          if (!data) return
          this.new_product = data.ProductSearchAndSpec?.ext_product
            ? {
                ...data.ProductSearchAndSpec?.ext_product,
                ext_system: { other: data.ProductSearchAndSpec?.ext_product?.ext_id }
              }
            : {}

          if (!data?.ProductSearchAndSpec?.total || !data?.ProductSearchAndSpec?.products?.length) {
            if (this.document_type === 'outgoing')
              if ([68, 150, 26].includes(this.searchString.length)) {
                EventBus.$emit('notify', {
                  unique: 'incoming-spec',
                  variant: 'danger',
                  title: 'Марка не найдена',
                  message: 'Марка, которую вы ищите отсутсвует у вас на балансе '
                })
                this.search_result = false
                this.searchString = ''
                this.$emit('clear-search')
              }
            this.found = []
            if (
              ((this.searchString + '').length === 13 || (this.searchString + '').length === 8) &&
              !isNaN(+this.searchString) &&
              !data.ProductSearchAndSpec?.products?.length
            ) {
              this.$refs.modal_global.showModal()
            }
            return
          }
          if (data) {
            this.error = false
            this.total = data?.ProductSearchAndSpec?.total ?? 0
            const result = await this.searchProductResult(data)
            if (this.pagination.skip) {
              this.found = [...this.found, ...result]
              return
            }
            this.found = result
          }

          this.loading = false
        },
        watchLoading(isLoading) {
          this.loading = isLoading
        },
        error() {
          this.loading = false
          this.error = true
        }
      }
    },

    props: {
      show_add_button: {
        type: Boolean,
        default: false
      },
      document: Object,
      supplier: {
        type: String,
        default: null
      },
      document_head: { type: String },
      document_type: {
        type: String,
        default: 'incoming'
      }
    },
    data() {
      return {
        lastTime: new Date(),
        searchStr: '',
        bottom: false,
        timer: null,
        new_product: {},
        product_name: '',
        barcodes: [],
        searchString: '',
        search_result: false,
        isModalOpened: false,
        show_product_modal: false,
        loading: false,
        time: 0,
        found: [],
        error: false,
        total: 0,
        pagination: {
          skip: 0,
          take: 10
        }
      }
    },
    watch: {
      show_product_modal() {
        if (!this.show_product_modal) {
          this.barcodes = []
        }
      }
    },
    mounted() {
      this.$root.$on('bv::modal::shown', () => {
        this.isModalOpened = true
      })
      this.$root.$on('bv::modal::hide', () => {
        this.isModalOpened = false
      })
      this.listenerKeyUp()
      this.$nextTick(() => this.scrollListener())
    },
    methods: {
      closeModal() {
        this.barcodes = []
        this.new_product = {}
        this.show_product_modal = false
      },
      clearInput() {},
      getRemainders(product) {
        if (!this.document?.storage?.id) return '-'
        return (
          (product.remainders?.find((el) => el?.storage?.id === this.document?.storage?.id)?.value || '0') +
          ' ' +
          product.measurement?.name
        )
      },
      scrollListener() {
        document.querySelector('.search-list').addEventListener('scroll', this.scrollDown)
        // window.removeEventListener('scroll', this.handleScroll)
      },

      scrollDown(event) {
        let bottomOfWindow = event.target.scrollTop + 80 + event.target.clientHeight >= event.target.scrollHeight
        if (bottomOfWindow) {
          if (this.loading || this.pagination.skip + this.pagination.take >= this.total) return
          this.pagination.skip +=
            this.pagination.skip > this.total ? this.total - this.pagination.skip : this.pagination.take
          this.$apollo.queries.ProductSearchAndSpec.refresh()
        }
      },

      search_product() {
        this.pagination.skip = 0
        if (this.searchString.length > 1) {
          return (this.search_result = true)
        }
        this.bottom = this.$refs.search_product.getBoundingClientRect().y < 470

        this.search_result = false
      },

      resetTimer() {
        this.time = 0
      },
      searchProductResult(data) {
        if (!data) return
        const result = data.ProductSearchAndSpec?.products ?? []

        if (
          (data?.ProductSearchAndSpec?.products?.length === 1 &&
            ((this.searchString + '').length === 13 || (this.searchString + '').length === 8) &&
            !isNaN(+this.searchString)) ||
          data?.ProductSearchAndSpec?.products?.[0]?.found_by_serial
        ) {
          this.search_result = false
          this.select_product(result[0])
          return
        }

        return result
      },
      select_product(item) {
        this.search_result = false
        this.$emit('select_product', { ...item, query: this.searchString })
        this.searchString = ''
      },
      clickOutside() {
        this.searchString = ''
        this.search_result = false
      },

      listenerKeyUp() {
        this.$root.$on('bv::modal::show', () => {
          this.isModalOpened = true
        })
        this.$root.$on('bv::modal::hide', () => {
          this.isModalOpened = false
        })
        if (this.isModalOpened) return

        window.addEventListener('keydown', (item) => {
          let time = new Date()
          if (time.getTime() - this.lastTime.getTime() > 50) this.searchStr = ''
          this.lastTime = time
          if (document.querySelector('input:focus')) return
          const key = item.key

          if (item.target.attributes.isKeyup || key === 'Alt') return false
          if (key === 'Escape' || key === 'Delete') return (this.searchString = '')

          if (key === 'Backspace')
            return (this.searchString = this.searchString.substring(0, this.searchString.length - 1))

          if (key === 'Enter' && this.searchStr.length > 3) {
            this.searchString = this.searchStr
            this.search_product()
            this.searchStr = ''
          }

          if (key.length === 1) {
            this.searchStr += key
          }
        })
      },
      openCreateModal(barcodes, clearNew) {
        if (Array.isArray(barcodes)) {
          this.barcodes = barcodes
        }
        if ((this.searchString + '').length === 13 && !isNaN(+this.searchString)) {
          this.product_name = ''
        } else {
          this.product_name = this.searchString
          this.barcodes = []
        }
        if (clearNew) {
          this.new_product = {}
        }
        this.show_product_modal = true
        setTimeout(() => this.$bvModal.show('create-product-modal'), 100)
      },
      saveProduct(product) {
        this.select_product(product)
        this.searchString = ''
        this.search_result = false
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      }),
      selectedSupplier() {
        return this.found?.filter((obj) => obj?.supplier?.id === this.supplier)
      },
      notSelectedSupplier() {
        return this.found?.filter((obj) => obj?.supplier?.id !== this.supplier)
      }
    }
  }
</script>

<style scoped lang="scss">
  .container-scanner {
    position: relative;
    width: 250px;
  }
  .scanner {
    position: absolute;
    top: 50%;
    right: 7px;
    bottom: 0;
    transform: translateY(-50%);
  }
  .b-search__wrapper > div {
    position: absolute !important;
    z-index: 10;
  }

  .create-product {
    width: 100%;
    display: inline-flex;
    align-items: center;
    padding: 8px 12px;
    gap: 8px;
    cursor: pointer;
    color: #313131;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
  }

  .remainder {
    white-space: nowrap;
    padding-left: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #313131;
    align-items: center;
    display: flex;
  }
  .b-search {
    &-product {
      width: 250px;
    }
    &__wrapper {
      width: 100%;
      height: 32px;
      z-index: 10;
    }

    &-result {
      position: absolute;

      left: 0px;
      width: 330px;
      background: #ffffff;
      border: 1px solid #e1e1e1;
      box-shadow: 0px 0px 40px 7px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      padding: 10px 0;
      max-height: 460px;
      overflow-y: auto;
      z-index: 10000;
      &.top {
        bottom: 10px !important;
      }
      &.bottom {
        top: 40px !important;
        bottom: auto;
      }
      .item {
        padding: 8px 14px;

        &-name {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #313131;
        }

        &-info {
          font-size: 12px;
          line-height: 24px;
          color: #888888;
          height: 24px;
          overflow: hidden;

          span {
            margin-right: 12px;
          }
        }

        &:hover {
          background: #f8f8f9;
        }
      }
    }
  }
  .supplier_title {
    padding: 12px 14px;
    margin-bottom: 0;
    font-family: 'Gilroy-Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #bcbcbc;
    &.border-b {
      border-top: 1px solid #e1e1e1;
    }
  }
</style>
